import React, { useEffect } from "react";
import Navbar from "../navigation/Navbar";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getAccessRefresh,
  selectAthlete,
  getStravaActivities,
  createStravaWebhook,
} from "../../features/strava/stravaSlice";

// Note:
// http://localhost:3000/registered/strava-callback
// ?state=&code=a367fe2d08313222333fa5a53ac78322914442012740&scope=read,activity:read_all

function StravaCallback() {
  const [loading, setLoading] = React.useState(false);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("StravaCallback MOUNTED");

    return () => {
      console.log("StravaCallback UNMOUNTED");
    };
  }, []);

  // Note: helper function to retry async actions
  const withRetry = async (
    action,
    actionName,
    maxAttempts = 3,
    delayMs = 1000
  ) => {
    for (let attempt = 1; attempt <= maxAttempts; attempt++) {
      try {
        const result = await action();
        console.log(`${actionName} succeeded on attempt ${attempt}`);
        return result;
      } catch (err) {
        console.error(`${actionName} attempt ${attempt} failed: `, err);
        // Note: so will re run if status code 500
        const isNonRetryErr =
          err.response?.status === 400 || // Note: bad request
          err.response?.status === 403 || // Note: unauthorized
          err.response?.status === 401; // Note: forbidden

        if (isNonRetryErr || attempt === maxAttempts) {
          console.error(
            `${actionName} failed after ${maxAttempts} attempts:`,
            err
          );
          throw err;
        }
        // Note: there was an error but retrying ...
        await new Promise((resolve) => setTimeout(resolve, delayMs));
      }
    }
  };

  useEffect(() => {
    const getStravaData = async () => {
      setLoading(true);
      const code = searchParams.get("code");
      const stravaAuthError = searchParams.get("error"); // Note: ?state=error ...?
      if (stravaAuthError) {
        setLoading(false);
        console.error("Strava authorization error: ", stravaAuthError);
        alert("Strava Authorization Error.");
        navigate("/registered/home", { replace: true });
      }
      // http://localhost:3000/registered/strava-callback?state=&code=0f1b299fcd470619a2788ea18979cdb6bedac65e&scope=read,activity:read
      try {
        if (code) {
          // Note: Step 1: Get access and refresh tokens
          const response = await withRetry(
            () => dispatch(getAccessRefresh({ code })).unwrap(),
            "getAccessRefresh"
          );
          console.log("Successfully fetched Strava tokens.");

          // Note: do not fetch activities if no strava access or refresh tokens
          if (!response) {
            throw new Error("Failed to fetched Strava tokens.");
          }

          if (response) {
            // Note: Step 2: Fetch Strava activities
            await withRetry(
              () => dispatch(getStravaActivities({})).unwrap(),
              "getStravaActivities"
            );
            console.log("Successfully fetched Strava activities.");
          }

          // Note: Step 3: Create webhook subscription
          const webHookResponse = await withRetry(
            () =>
              dispatch(
                createStravaWebhook({ webhook: "createSubscription" })
              ).unwrap(),
            "createStravaWebhook"
          );
          console.log("Webhook created successfully:", webHookResponse);

          // Note: all dispatched Strava setup actions were successful
          navigate("/registered/home", { replace: true });
        }
      } catch (err) {
        console.error("Strava setup failed: ", err);
        alert("Failed to connect to Strava. Please try again later.");

        // TODO delete strava profile and activities data and offer to retry...
        navigate("/registered/home", {
          replace: true,
          state: { stravaSetupFailed: true, error: err.message },
        });
      } finally {
        setLoading(false);
      }
    };
    getStravaData();
  }, []);
  return (
    <>
      <Navbar />
      <p>Strava authorization</p>
      {loading && <p>Please wait a moment.</p>}
    </>
  );
}

export default StravaCallback;
