// TODO !!! Remember design needs to be slightly weird, should make user want to explore
//      !!! No one needs another activity tracker or Calendar!

// TODO stats should be based on my 1000 km article ... need to create frequency distribution, pace, median, categories

import React, { useState, useEffect } from "react";
import Navbar from "../components/navigation/Navbar";
import { useSelector, useDispatch } from "react-redux";
import {
  selectActivities,
  selectAthlete,
  getStravaProfileCol,
  getStravaActivityCol,
  // Note: below actions for updates via realtime connection
  addNewActivity,
  updateActivity,
  deleteActivity,
} from "../features/strava/stravaSlice";
import { selectUser } from "../features/user/userSlice";
import { useLocation } from "react-router-dom";

import client, { databases } from "../appwrite";

import Calendar from "../components/Calendar";

function AuthedHome() {
  const [retrySetup, setRetrySetup] = useState(false);
  const dispatch = useDispatch();

  const location = useLocation();

  const athlete = useSelector(selectAthlete);
  const activities = useSelector(selectActivities);
  const userId = useSelector(selectUser).$id;

  console.log("AuthedHome: userId: ", userId);
  console.log("AuthedHome: activities: ", activities);

  // Note: for debugging ...
  useEffect(() => {
    console.log("AuthedHome MOUNTED");

    return () => {
      console.log("AuthedHome UNMOUNTED");
    };
  }, []);

  if (location?.state?.stravaSetupFailed) {
    setRetrySetup(true);
  }

  // Note: when component first mounts, when user authorizes Strava, when useSelector forces re-render
  //       only make request to profile and activities collection when no data in Redux state
  //       further update maintained by realtime connection which forces re-render via useSelector
  useEffect(() => {
    const getData = async () => {
      console.log("AuthedHome: getData()...");

      if (!athlete) {
        try {
          console.log("dispatching getStravaProfileCol ...");
          await dispatch(getStravaProfileCol({ userId })).unwrap();
        } catch (err) {
          console.error("Error getting athlete data : ", err);
          alert("Error getting athlete data."); // TODO state error and errorMessage variable.
        }
      }
      // Note: fetch strava activities on component mount
      if (!activities) {
        try {
          console.log("dispatching getStravaActivityCol ...");
          await dispatch(getStravaActivityCol({ userId })).unwrap();
        } catch (err) {
          console.error("Error getting activities data : ", err);
          alert("Error getting activities data.");
        }
      }
    };

    getData();
  }, []);

  // Note: watch for realtime updates in Strava activities collection
  //       if activity is created/updated/deleted the state will change
  //       and the activities selector will re-render this component
  useEffect(() => {
    console.log("client.subscribe()....");
    const unsubscribe = client.subscribe(
      `databases.${process.env.REACT_APP_APPWWRITE_DB_ID}.collections.${process.env.REACT_APP_APPWWRITE_STRAVA_ACTIVITY_ID}.documents`,
      (response) => {
        console.log("Real-time update received:", response);
        const { events, payload } = response;

        if (
          events.includes(
            "databases.*.collections.strava_activities.documents.*.create"
          )
        ) {
          console.log("New Activity Created:", payload);
          dispatch(addNewActivity(payload)); // ✅ Add to Redux state
        } else if (
          events.includes(
            "databases.*.collections.strava_activities.documents.*.update"
          )
        ) {
          console.log("Activity Updated:", payload);
          dispatch(updateActivity(payload)); // ✅ Update in Redux
        } else if (
          events.includes(
            "databases.*.collections.strava_activities.documents.*.delete"
          )
        ) {
          console.log("Activity Deleted:", payload);
          dispatch(deleteActivity(payload.$id)); // ✅ Remove from Redux
        }
      }
    );
    return () => {
      unsubscribe();
    };
  }, []);

  // Note: user/athlete redirected to accept scopes/give permission to my app
  // http://www.strava.com/oauth/authorize?client_id=[YOUR_ID_123]&response_type=code&redirect_uri=http://localhost/exchange_token&approval_prompt=force&scope=activity:read_all

  //  Note: activity:read_all
  //  The same access as activity:read, plus
  //  privacy zone data and access to read the user's activities with visibility set to Only You

  const connectToStrava = () => {
    const clientId = process.env.REACT_APP_STRAVA_CLIENT_ID;
    if (process.env.NODE_ENV === "development") {
      console.log("clientId: ", clientId);
    }
    const redirectUri =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/registered/strava-callback"
        : "https://sunnycodesapp.com/registered/strava-callback";

    const requiredScope = "activity:read"; // Note: previously read_all
    const authStravaAccessUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=auto&scope=${requiredScope}`;

    window.location.href = authStravaAccessUrl;
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Navbar />
      <p>Verified User Home Page</p>
      <button onClick={refreshPage}>Refresh</button>
      {(!athlete || !Array.isArray(activities) || activities.length === 0) && (
        <button onClick={connectToStrava}>Authorize Strava</button>
      )}
      {retrySetup && <button onClick={connectToStrava}>Retry</button>}
      <Calendar activities={activities} />
      {Array.isArray(activities) && activities.length > 0 ? (
        <>
          <p>Your latest activities</p>
          <ul>
            {activities.map((activity) => (
              <li key={activity.$id}>
                Date: {activity.startDateLocal}| Name: {activity.name} |
                Distance: {activity.distance} | Total Elapsed Time:{" "}
                {activity.elapsedTime} | Total Elevation Gain:{" "}
                {activity.totalElevationGain}
              </li>
            ))}
          </ul>
        </>
      ) : (
        <>
          <p>No activities found.</p>
        </>
      )}
      {/* TODO This application requires access to your Strava data like profile info and activity data. */}
      {/* TODO Activities marked in Strava as "only you" are not requested. App requires access to activities marked as "Everyone" or "Followers Only"*/}
    </>
  );
}

export default AuthedHome;
