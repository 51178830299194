import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as ReactRouterLink } from "react-router-dom";
import {
  logout,
  selectIsLoggedIn,
  selectUser,
} from "../../features/user/userSlice";

function Navbar() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  console.log("Navbar: isLoggedIn: ", isLoggedIn);

  const currentUser = useSelector(selectUser);
  // console.log("Navbar: currentUser: ", currentUser);

  return (
    <nav>
      <a href="/">Home</a>
      <div>
        {isLoggedIn ? (
          <>
            <span>{currentUser.$id}</span>
            <button type="button" onClick={() => dispatch(logout())}>
              Logout
            </button>
          </>
        ) : (
          <>
            <a href="/sign-in">Sign in</a>
            <br />
            <a href="/sign-up">Sign up</a>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
