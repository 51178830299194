import React, { useState, useMemo, useEffect } from "react";
import dayjs from "dayjs";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import {
  Box,
  Typography,
  Grid2,
  Paper,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";

// Note: helper methods
const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const generateDates = (month = dayjs().month(), year = dayjs().year()) => {
  const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
  const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

  const arrayOfDates = [];

  // Prefix dates to align calendar
  for (let i = 0; i < firstDateOfMonth.day(); i++) {
    arrayOfDates.push({ currentMonth: false, date: firstDateOfMonth.day(i) });
  }

  // Days of the current month
  for (let i = 1; i <= lastDateOfMonth.date(); i++) {
    arrayOfDates.push({
      currentMonth: true,
      date: firstDateOfMonth.date(i),
      today:
        firstDateOfMonth.date(i).toDate().toDateString() ===
        dayjs().toDate().toDateString(),
    });
  }

  // Fill the remaining days for a full 6x7 Grid2
  const remainingDays = 42 - arrayOfDates.length;
  for (let i = 1; i <= remainingDays; i++) {
    arrayOfDates.push({ currentMonth: false, date: lastDateOfMonth.date(i) });
  }

  return arrayOfDates;
};

// Note: end of helper methods

const Calendar = (props) => {
  const { activities } = props;
  console.log("Calendar: activities: ", activities);

  const currentDate = dayjs();
  const [today, setToday] = useState(currentDate);
  const [selectDate, setSelectDate] = useState(currentDate);
  const [activitiesForDay, setActivitiesForDay] = useState([]);

  const days = ["S", "M", "T", "W", "T", "F", "S"];

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const activitiesByDate = useMemo(() => {
    if (!activities) return {};
    return activities?.reduce((acc, activity) => {
      const dateKey = dayjs(activity?.startDateLocal).format("YYYY-MM-DD");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      //   TODO push an object and mark that it is in current month {activity, currentMonth:true}
      acc[dateKey].push(activity);
      return acc;
    }, {});
  }, [activities]);

  console.log("activitiesByDate: ", activitiesByDate);

  useEffect(() => {
    const dateKey = dayjs(selectDate).format("YYYY-MM-DD");
    setActivitiesForDay(activitiesByDate[dateKey] || []);
  }, [selectDate]);

  return (
    <Box
      display="flex"
      gap={2}
      justifyContent="center"
      alignItems="center"
      flexDirection={isSmallScreen ? "column" : "row"}
      height="100vh"
      //   border="1px solid red"
    >
      {/* Note:  calendar container */}
      <Paper
        sx={{
          width: isSmallScreen ? 350 : 375,
          height: isSmallScreen ? 350 : 375,
          p: 1,
          backgroundColor: "#1C1C1E",
          color: "#E5E5EA",
        }}
        // elevation={3} // changes the background color of Paper component
      >
        {/* Note: header */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {months[today.month()]} {today.year()}
          </Typography>
          {/* Note: navigation buttons */}
          <Box display="flex" gap={2} alignItems="center">
            <IconButton
              onClick={() => setToday(today.month(today.month() - 1))}
              size="small"
              sx={{ color: "white" }}
            >
              <GrFormPrevious />
            </IconButton>
            <Typography
              sx={{
                cursor: "pointer",
                "&:hover": { transform: "scale(1.05)", transition: "0.2s" },
              }}
              onClick={() => setToday(currentDate)}
            >
              Today
            </Typography>
            <IconButton
              onClick={() => setToday(today.month(today.month() + 1))}
              size="small"
              sx={{ color: "white" }}
            >
              <GrFormNext />
            </IconButton>
          </Box>
        </Box>

        {/* Note: days of the week headings */}
        <Grid2 container spacing={1} justifyContent="center">
          {days.map((day, index) => (
            // Note: remove item prop from Grid2 as was giving a warning
            <Grid2 key={index} xs={1}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={40}
                height={40}
              >
                <Typography align="center" fontSize={14}>
                  {day}
                </Typography>
              </Box>
            </Grid2>
          ))}
        </Grid2>

        {/* Note: calendar dates */}
        <Grid2 container spacing={1} justifyContent="center">
          {generateDates(today.month(), today.year()).map(
            ({ currentMonth, date, today }, index) => {
              const dateKey = dayjs(date).format("YYYY-MM-DD");
              const activitiesForDay = activitiesByDate[dateKey] || [];
              return (
                <Grid2 key={index} xs={1}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    //   Note: adjust sizes for small and large screen
                    width={40}
                    height={40}
                    border={currentMonth ? "2px solid #3A3A3C" : ""}
                    borderRadius={1}
                    bgcolor={today ? "red" : "transparent"}
                    color={
                      today ? "white" : currentMonth ? "#E5E5EA" : "transparent"
                    }
                    sx={{
                      cursor: currentMonth ? "pointer" : "",
                      "&:hover": {
                        bgcolor: currentMonth ? "blue" : "",
                        color: currentMonth ? "white" : "",
                        transition: "0.2s",
                      },
                    }}
                    onClick={() => setSelectDate(date)}
                  >
                    <Typography fontSize={14}>{date.date()}</Typography>
                    {/* 🏁 Show activity indicators */}
                    {/* TODO fix this is within a map */}
                    {activitiesForDay.length > 0 && currentMonth && (
                      <Box mt={0.5}>
                        {activitiesForDay
                          //   .filter(
                          //     (activity) =>
                          //       dayjs(activity.startDateLocal).month() ===
                          //       selectedMonth
                          //   )
                          .slice(0, 1)
                          .map((activity, idx) => (
                            // only map if activity is within current month
                            <Box
                              key={idx}
                              sx={{
                                width: 6,
                                height: 6,
                                bgcolor: "green",
                                borderRadius: "50%",
                                display: "inline-block",
                                mx: 0.3,
                              }}
                            />
                          ))}
                      </Box>
                    )}
                  </Box>
                </Grid2>
              );
            }
          )}
        </Grid2>
      </Paper>

      {/* Note: activities section */}
      <Paper
        sx={{
          width: isSmallScreen ? 350 : 375,
          height: isSmallScreen ? 350 : 375,
          p: 1,
          backgroundColor: "#1C1C1E",
          color: "#E5E5EA",
        }}
      >
        <Typography variant="h6" fontWeight="bold">
          Activities for {selectDate.toDate().toDateString()}
        </Typography>
        <Typography>Activities data: </Typography>
        {activitiesForDay.length > 0 ? (
          activitiesForDay.map((activity, idx) => (
            <Box
              key={idx}
              sx={{
                p: 1,
                my: 1,
                bgcolor: "#333",
                borderRadius: 1,
              }}
            >
              <Typography>
                Name: {activity.name} Distance: {activity.distance} Time{" "}
                {activity.elapsedTime}
              </Typography>
            </Box>
          ))
        ) : (
          <Typography>No data. Work harder.</Typography>
        )}
      </Paper>
    </Box>
  );
};

export default Calendar;
