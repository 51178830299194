import React, { useState, useEffect } from "react";
import Navbar from "../components/navigation/Navbar";
import { useSelector, useDispatch } from "react-redux";

import {
  selectAthlete,
  selectActivities,
  viewStravaWebhook,
  getStravaProfileCol,
  getStravaActivityCol,
  selectWebhookStatus,
  selectWebhookDetails,
} from "../features/strava/stravaSlice";

import { selectUser } from "../features/user/userSlice";

function UserProfile() {
  const [authedStrava, setAuthedStrava] = useState(true);

  const dispatch = useDispatch();
  const athlete = useSelector(selectAthlete);
  const activities = useSelector(selectActivities);
  const user = useSelector(selectUser);

  const webhookStatus = useSelector(selectWebhookStatus);
  const webhookDetails = useSelector(selectWebhookDetails);

  // Note: for debugging ...
  useEffect(() => {
    console.log("UserProfile MOUNTED");

    return () => {
      console.log("UserProfile UNMOUNTED");
    };
  }, []);

  // TODO: retry until all activities deleted and also profile info removed
  const handleStravaDeAuthClick = () => {
    setAuthedStrava(false);
    // Note: this should set off a webhook event of type athlete
    window.open("https://www.strava.com/settings/apps", "_blank");
  };

  useEffect(() => {
    if (!authedStrava && athlete) {
      dispatch(getStravaProfileCol({ userId: user.$id })).unwrap();
    }
  }, [authedStrava]);

  const refreshPage = () => {
    window.location.reload();
  };

  const connectToStrava = () => {
    const clientId = process.env.REACT_APP_STRAVA_CLIENT_ID;
    if (process.env.NODE_ENV === "development") {
      console.log("clientId: ", clientId);
    }
    const redirectUri =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3000/registered/strava-callback"
        : "https://sunnycodesapp.com/registered/strava-callback";

    const requiredScope = "activity:read"; // Note: previously read_all
    const authStravaAccessUrl = `https://www.strava.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUri}&approval_prompt=auto&scope=${requiredScope}`;

    window.location.href = authStravaAccessUrl;
  };

  const handleViewWebhookClick = async () => {
    try {
      await dispatch(viewStravaWebhook({})).unwrap();
    } catch (err) {
      console.log("UserProfile: handleViewWebhookClick:", err);
    }
  };

  console.log("UserProfile: user: ", user);

  return (
    <>
      <Navbar />
      <h1>User Profile Page</h1>
      {webhookStatus ? <p>{webhookStatus}</p> : ""}
      {webhookDetails ? <p>{JSON.stringify(webhookDetails, null, 2)}</p> : ""}
      {webhookStatus && (
        <button onClick={handleViewWebhookClick}>View Webhook</button>
      )}
      {athlete ? (
        <>
          <p>Strava Profile Info</p>
          <p>{JSON.stringify(athlete, null, 2)}</p>
        </>
      ) : (
        <>
          {" "}
          <p>Strava Profile Info</p>
          <p>No data.</p>
        </>
      )}
      {authedStrava ? (
        <button onClick={handleStravaDeAuthClick}>Deauthorize Strava</button>
      ) : (
        <button onClick={connectToStrava}>Authorize Strava</button>
      )}
    </>
  );
}

export default UserProfile;
