import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "./logo.svg";
import "./App.css";
import Login from "./pages/Login";
import SignIn from "./pages/sign-in/SignIn";
import SignUp from "./pages/sign-up/SignUp";
import Home from "./pages/Home";
import PersistLogin from "./components/auth/PersistLogin";
import RequireAuth from "./components/auth/RequireAuth";
import AuthedHome from "./pages/AuthedHome";

import AppTheme from "../src/theme/shared-theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";

import SendVerifyEmail from "./components/auth/SendVerifyEmail";
import CompleteEmailVerify from "./components/auth/CompleteEmailVerify";

import RequireVerification from "./components/auth/RequireVerification";

import ResetPassword from "./components/auth/ResetPassword";

import StravaCallback from "./components/strava/StravaCallback";

import UserProfile from "./pages/UserProfile";

function App(props) {
  return (
    <>
      <AppTheme {...props}>
        <CssBaseline enableColorScheme />
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path="/" element={<Home />} />
            <Route path="sign-in" element={<SignIn />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="reset" element={<ResetPassword />} />

            <Route element={<RequireAuth />}>
              <Route path="verify-email" element={<CompleteEmailVerify />} />
              <Route path="send-verify-email" element={<SendVerifyEmail />} />
              <Route element={<RequireVerification />}>
                <Route path="registered/home" element={<AuthedHome />} />
                <Route
                  path="registered/user-profile"
                  element={<UserProfile />}
                />
                <Route
                  path="registered/strava-callback"
                  element={<StravaCallback />}
                />
              </Route>
            </Route>
          </Route>
        </Routes>
      </AppTheme>
    </>
  );
}

export default App;
