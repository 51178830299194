import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { selectUser } from "../user/userSlice";
import { databases } from "../../appwrite";
import { Query } from "appwrite";

const initialState = {
  status: "idle", // 'idle' | 'loading' | 'error'
  error: null,
  athleteInfo: null, // {}
  activities: null,
  // authorized: false,
  webhook: false,
  webhookDetails: null,
};

// TODO use client side api to query strava_profile_collection
// https://github.com/divanov11/Appwrite-React-quickstart/blob/main/Part-3/src/appwrite/databases.js
// export const getStravaAthleteInfo = createAsyncThunk();

export const getAccessRefresh = createAsyncThunk(
  "strava/getAccessRefresh",
  async ({ code }, { rejectWithValue, getState }) => {
    try {
      const reduxState = getState();
      const userId = selectUser(reduxState).$id;

      if (!userId) {
        throw new Error("User id not found in state.");
      }

      const response = await axios.get(
        `https://67b942d2c410974fd869.functions.mybackend.sunnycodesapp.com`,
        {
          // TODO pass in body instead of params ... need to edit backend fn
          params: { code, userId },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data; // Note: this contains the athlete info and tokens
    } catch (err) {
      console.log("getAccessRefresh: err: ", err);
      const errorMessage =
        err.response?.data?.error || err.message || "Something went wrong.";
      return rejectWithValue(errorMessage);
    }
  }
);

// Note: function makes request to Strava API and saves activities of athlete into Appwrite collection
export const getStravaActivities = createAsyncThunk(
  "strava/getStravaActivities",
  async ({}, { rejectWithValue, getState }) => {
    try {
      const reduxState = getState();
      // Note: correct use of $id which in Appwrite Auth service is user ID not doc id
      // state.user.userInfo.$id
      const userId = selectUser(reduxState).$id;
      if (!userId) {
        throw new Error("User id not found in state.");
      }
      const response = await axios.get(
        `https://67b9431f8b3e3bd10117.functions.mybackend.sunnycodesapp.com`,
        {
          // TODO pass in body instead of params ... need to edit backend fn
          params: { userId },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("getStravaActivities: response.data: ", response.data);
      return response.data.message; // Note: Successfully saved athlete Strava activities.
    } catch (err) {
      console.log("getStravaActivities: err: ", err);
      const errorMessage =
        err.response?.data?.error || err.message || "Something went wrong.";
      return rejectWithValue(errorMessage);
    }
  }
);

export const getStravaProfileCol = createAsyncThunk(
  "strava/getStravaProfileCol",
  async ({ userId }, { rejectWithValue }) => {
    try {
      if (!userId) {
        throw new Error("User id not provided to getStravaProfileCol.");
      }

      const response = await databases.listDocuments(
        process.env.REACT_APP_APPWWRITE_DB_ID, // databaseId
        process.env.REACT_APP_APPWWRITE_STRAVA_PROFILE_ID, // collectionId
        [Query.equal("appUserId", userId)]
      );
      return response.documents[0];
    } catch (err) {
      console.log("getStravaProfileCol: err: ", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Something went wrong with getStravaProfileCol.";
      return rejectWithValue(errorMessage);
    }
  }
);

// TODO !!! Do not send back sensitive info like users access and refresh token ...
// Note: https://appwrite.io/docs/products/databases/pagination
export const getStravaActivityCol = createAsyncThunk(
  "strava/getStravaActivityCol",
  async ({ userId }, { rejectWithValue }) => {
    try {
      if (!userId) {
        throw new Error("User id not provided to getStravaActivityCol.");
      }

      const allDocuments = [];
      const limit = 25; // Max limit per request
      let offset = 0;
      let hasMore = true;

      while (hasMore) {
        const response = await databases.listDocuments(
          process.env.REACT_APP_APPWWRITE_DB_ID, // databaseId
          process.env.REACT_APP_APPWWRITE_STRAVA_ACTIVITY_ID, // collectionId
          [
            Query.equal("appUserId", userId),
            Query.limit(limit),
            Query.offset(offset),
          ]
        );

        allDocuments.push(...response.documents);

        // Note: if current response less than limit no more docs to fetch
        if (response.documents.length < limit) {
          hasMore = false;
        } else {
          offset += limit; // Note: increment offset by limit
        }
      }

      // console.log("getStravaActivityCol: allDocuments: ", allDocuments);
      return { total: allDocuments.length, documents: allDocuments }; // Return all documents
    } catch (err) {
      console.log("getStravaActivityCol: err: ", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Something went wrong with getStravaActivityCol.";
      return rejectWithValue(errorMessage);
    }
  }
);

// Note: dispatch(createStravaWebhook({ webhook: "createSubscription" }))
export const createStravaWebhook = createAsyncThunk(
  "strava/createStravaWebhook",
  async ({ webhook }, { rejectWithValue, getState }) => {
    try {
      const reduxState = getState();
      const userId = selectUser(reduxState).$id;
      if (!userId) {
        throw new Error("User id not provided to createStravaWebhook.");
      }

      // TODO make request with req.query ?webhook=createSubscription
      const response = await axios.get(
        `https://67b943583ea050fb010d.functions.mybackend.sunnycodesapp.com`,
        {
          params: { webhook },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      // Note: 1) Webhook subscription already exists. 2) Sent create webhook subscription request.
      console.log("createStravaWebhook: response.data: ", response.data);

      return { createdWebhookSubscription: true };
    } catch (err) {
      console.log("createStravaWebhook: err: ", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Something went wrong with createStravaWebhook.";
      return rejectWithValue(errorMessage);
    }
  }
);

export const viewStravaWebhook = createAsyncThunk(
  "strava/viewStravaWebhook",
  async (_, { rejectWithValue, getState }) => {
    try {
      const reduxState = getState();
      const userId = selectUser(reduxState).$id;
      if (!userId) {
        throw new Error("User id not provided to createStravaWebhook.");
      }
      const webhook = "viewSubscription";
      const response = await axios.get(
        `https://67b943583ea050fb010d.functions.mybackend.sunnycodesapp.com`,
        {
          params: { webhook },
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("viewStravaWebhook: response.data: ", response.data);
      return response.data.data;
    } catch (err) {
      console.log("viewStravaWebhook: err: ", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "Something went wrong with viewStravaWebhook.";
      return rejectWithValue(errorMessage);
    }
  }
);

// TODP Get Athlete Stats (getStats) ... for a detailed user profile page ...
// https://developers.strava.com/docs/reference/#api-Activities-getLoggedInAthleteActivities

export const stravaSlice = createSlice({
  name: "strava",
  initialState,
  reducers: {
    addNewActivity: (state, action) => {
      state.activities.unshift(action.payload); // Add new activity at the top
    },
    updateActivity: (state, action) => {
      const index = state.activities.findIndex(
        (activity) => activity.$id === action.payload.$id
      );
      if (index !== -1) {
        state.activities[index] = action.payload;
      }
    },
    deleteActivity: (state, action) => {
      state.activities = state.activities.filter(
        (activity) => activity.$id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder
      // getAccessRefresh
      .addCase(getAccessRefresh.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getAccessRefresh.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.athleteInfo = action.payload; // Save the athlete info from the backend, thus athlete authorized Strava access
      })
      .addCase(getAccessRefresh.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // getStravaActivities
      .addCase(getStravaActivities.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStravaActivities.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        // Note:
        // This action runs Apppwrite function to fetch and save Strava activities to my collection.
        // state.activities = action.payload;
      })
      .addCase(getStravaActivities.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // getStravaProfileCol
      .addCase(getStravaProfileCol.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStravaProfileCol.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.athleteInfo = action.payload; // Note: Save profile info from collection to Redux state
      })
      .addCase(getStravaProfileCol.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // getStravaActivityCol
      .addCase(getStravaActivityCol.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(getStravaActivityCol.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.activities = action.payload.documents; // Note: Save activities from collection to Redux state
        // state.totalActivities = action.payload.total;
      })
      .addCase(getStravaActivityCol.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      //  subscribeStravaWebhook
      .addCase(createStravaWebhook.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(createStravaWebhook.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.webhook = action.payload.createdWebhookSubscription;
      })
      .addCase(createStravaWebhook.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      })
      // viewStravaWebhook
      .addCase(viewStravaWebhook.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(viewStravaWebhook.fulfilled, (state, action) => {
        state.status = "idle";
        state.error = null;
        state.webhookDetails = action.payload;
      })
      .addCase(viewStravaWebhook.rejected, (state, action) => {
        state.status = "error";
        state.error = action.payload;
      });
    // TODO deleteStravaWebhook
  },
});

export const selectAthlete = (state) => state.strava.athleteInfo;
export const selectActivities = (state) => state.strava.activities;

export const selectWebhookStatus = (state) => state.strava.webhook;
export const selectWebhookDetails = (state) => state.strava.webhookDetails;

export const { addNewActivity, updateActivity, deleteActivity } =
  stravaSlice.actions;

export default stravaSlice.reducer;
